<template>
  <v-container v-if="!loading">
    <v-row v-if="report">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ `${report.studentFullName} session report` }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card hover>
          <v-card-title>
            {{ `${$_t("attribute.classroomName")}: ${report.classroomName}` }}
          </v-card-title>
          <v-card-title>
            {{
              `${$_t("attribute.lessonType")}: ${$_t(
                `attribute.${report.lessonType}`
              )}`
            }}
          </v-card-title>
          <v-card-title>
            {{ `${$_t("attribute.teacherName")}: ${report.teacherName}` }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card hover>
          <v-card-title>
            {{
              `${$_t("attribute.classroomNumber")}: ${report.classroomNumber}`
            }}
          </v-card-title>
          <v-card-title>
            {{
              `${$_t("attribute.startTime")}: ${getMainTime(
                report.startTime * 1000,
                "YYYY-MM-DD, h:mm a"
              )}`
            }}
          </v-card-title>
          <v-card-title>
            {{
              `${$_t("attribute.entTime")}: ${getMainTime(
                report.endTime * 1000,
                "YYYY-MM-DD, h:mm a"
              )}`
            }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card hover>
          <v-card-title> Presentation info </v-card-title>
          <v-card-text class="text-break">
            {{
              `${$_t(`attribute.isPresent`)}: ${
                report.presenceStatus.isPresent ? "Present" : "Absent"
              }`
            }}
          </v-card-text>
          <v-card-text class="text-break">
            {{
              `${$_t(`attribute.feedBack`)}: ${report.presenceStatus.feedBack}`
            }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card hover>
          <v-card-title> Teaching info </v-card-title>
          <v-card-text>
            {{
              `${$_t("attribute.description")}: ${
                report.teachingInformation.description
              }`
            }}
          </v-card-text>
          <v-card-text>
            {{
              `${$_t("attribute.total_teach_time")}: ${
                report.teachingInformation.totalTeachedMinutes
              } minutes`
            }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card hover>
          <v-card-title> Lessons </v-card-title>
          <v-card-subtitle
            class="text-break"
            v-for="(lesson, index) in report.teachingInformation
              .lessonsInformation"
            :key="index"
          >
            <span class="mr-3 text-body-2">{{ index + 1 }}</span>
            {{
              `${$_t(`attribute.${lesson.lessonLevel}`)}, ${
                lesson.lessonName
              }, ${lesson.lessonTitle}, ${lesson.lessonSubTitle}`
            }}
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card hover>
          <v-card-title>{{ $_t("attribute.homework") }}</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>

                  <th class="text-left">
                    {{ $_t("attribute.lessonName") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.lessonTitle") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.subtitle") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.level") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.homeworkSubject") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.deadLine") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(homework, index) in report.homeWorks" :key="index">
                  <td data-label="#">{{ index + 1 }}</td>

                  <td data-label="lesson name">
                    {{ homework.lessonName }}
                  </td>
                  <td data-label="lesson title">
                    {{ homework.lessonTitle }}
                  </td>
                  <td data-label="lesson title">
                    {{ homework.lessonSubTitle }}
                  </td>
                  <td data-label="lesson level">
                    {{ $_t(`attribute.${homework.lessonLevel}`) }}
                  </td>
                  <td data-label="homework subject">
                    {{ homework.homeworkSubject }}
                  </td>
                  <td data-label="DeadLine">
                    {{ $_date(homework.deadLine) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <!--      <v-col cols="12">-->
      <!--        <v-card hover>-->
      <!--          <v-card-title>Exams</v-card-title>-->
      <!--          <v-simple-table>-->
      <!--            <template v-slot:default>-->
      <!--              <thead>-->
      <!--                <tr>-->
      <!--                  <th class="text-left">#</th>-->
      <!--                  <th class="text-left">{{ $_t("attribute.status") }}</th>-->
      <!--                  <th class="text-left">{{ $_t("attribute.score") }}</th>-->
      <!--                  <th class="text-left">-->
      <!--                    {{ $_t("attribute.destination") }}-->
      <!--                  </th>-->
      <!--                  <th class="text-left">-->
      <!--                    {{ $_t("attribute.exam_feedback") }}-->
      <!--                  </th>-->
      <!--                  <th class="text-left">-->
      <!--                    {{ $_t("attribute.exam_type") }}-->
      <!--                  </th>-->
      <!--                </tr>-->
      <!--              </thead>-->
      <!--              <tbody>-->
      <!--                <tr v-for="(exam, i) in report.exams" :key="i">-->
      <!--                  <td data-label="#">{{ i + 1 }}</td>-->
      <!--                  <td data-label="State">{{ exam.examState }}</td>-->
      <!--                  <td data-label="Score">-->
      <!--                    {{ exam.score || "No score reported" }}-->
      <!--                  </td>-->
      <!--                  <td data-label="Destination lesson">-->
      <!--                    {{ exam.destination }}-->
      <!--                  </td>-->
      <!--                  <td data-label="Exam feedback">-->
      <!--                    {{ exam.examFeedback }}-->
      <!--                  </td>-->
      <!--                  <td data-label="Exam type">-->
      <!--                    {{ $_t(`attribute.examTypes.${exam.type}`) }}-->
      <!--                  </td>-->
      <!--                </tr>-->
      <!--              </tbody>-->
      <!--            </template>-->
      <!--          </v-simple-table>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card-title> Link expired </v-card-title>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getMainTime } from "@/tools/Utils";
import { mapGetters } from "vuex";

export default {
  name: "ParentReport",
  data() {
    return {
      report: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  methods: {
    getMainTime,
    async getSessionReport() {
      this.report = await this.$actions.getSessionReport(
        this.$route.params.reportId
      );
    },
  },
  mounted() {
    this.getSessionReport();
  },
};
</script>

<style scoped></style>
